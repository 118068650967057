"use client";
import { Button } from "@headlessui/react";
import SignInDialog from "@src/app/SignIn/SignInDialog";
import { banner } from "@src/constants";
import { RootState } from "@src/store/store";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function Banner() {
  const [isOpen, setIsOpen] = useState(false);
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();

  const onSignIn = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleButton = () => {
    if (wallet.instance !== null) {
      router.push("/drep/mint");
    } else {
      onSignIn();
    }
  };

  return (
    <div className="max-w-[1440px] flex justify-center items-center flex-col mt-[50px] w-full px-[50px] sm:px-[120px]">
      <div className="flex flex-col justify-center items-center w-full rounded-[24px] bg-banner-background bg-cover bg-no-repeat sm:py-24 py-[60px] ">
        <p className="h1 font-bold  text-neutral-900 text-center max-w-[954px]">
          {banner.title}
        </p>
        <p className="body1 mt-8 mb-16 text-neutral-600 max-w-[758px] text-center">
          {banner.description}
        </p>
        <Button
          className="bg-primary-600 px-6 py-4 w-fit text-neutral-100 body1 font-semibold rounded-[8px]"
          onClick={handleButton}
        >
          {wallet.instance === null ? "Connect Wallet" : "Mint DRep ID"}
        </Button>
        <SignInDialog isOpen={isOpen} onClose={onClose} />
      </div>
    </div>
  );
}
