import { IFeature } from "@src/models/dtos";
import React from "react";

export default function FeatureCard(feature: IFeature) {
  return (
    <div className="flex gap-6 items-start">
      <div className="h-[64px] w-[64px]">
        {React.createElement(feature.icon)}
      </div>
      <div className="flex flex-col gap-2">
        <p className="h5">{feature.title}</p>
        <p className="body2 font-semibold text-neutral-800">
          {feature.description}
        </p>
      </div>
    </div>
  );
}
