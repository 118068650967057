import { IHowItWork } from "@src/models/dtos";
import Image from "next/image";

export default function HowItWorksCard(step: IHowItWork) {
  return (
    <div className="rounded-[16px] shadow-how-it-works-card px-8 py-7 flex flex-col">
      <div className="h-[76px] w-[76px] bg-white shadow-how-it-works-card-icon rounded-[16px] p-[10px]">
        <Image
          src={step.icon}
          className="h-full w-full"
          alt="how it works icons"
        />
      </div>
      <p className="h5 mt-10 mb-3 font-bold text-secondary-900">{step.title}</p>
      <p className="body3 text-neutral-700">{step.description}</p>
    </div>
  );
}
