"use client";
import Banner from "@src/components/landing-sections/banner";
import Features from "@src/components/landing-sections/features";
import GetStarted from "@src/components/landing-sections/getStarted";
import HowItWorks from "@src/components/landing-sections/howItWorks";
import withWalletEventListener from "@src/walletEvent/walletStateUtils";
import { useEffect, useState } from "react";

function Home() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // This ensures that rendering only happens after the component is mounted on the client.
    // Prevent Hydration error
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return (
      <div className="min-h-calc-548 bg-white w-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-14 w-14 border-b-2 border-primary-900"></div>
      </div>
    );
  }

  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <Banner />
      <HowItWorks />
      <Features />
      <GetStarted />
    </div>
  );
}
export default withWalletEventListener(Home);
