import React from "react";

export default function UniqueDRepIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="64"
        height="64"
        rx="32"
        fill="url(#paint0_linear_373_1899)"
      />
      <path
        d="M36.5173 18.4359C37.1112 17.8419 37.8164 17.3707 38.5925 17.0492C39.3686 16.7278 40.2004 16.5623 41.0404 16.5623C41.8805 16.5623 42.7123 16.7278 43.4884 17.0492C44.2645 17.3707 44.9696 17.8419 45.5636 18.4359C46.1576 19.0299 46.6288 19.735 46.9503 20.5111C47.2717 21.2872 47.4372 22.119 47.4372 22.9591C47.4372 23.7991 47.2717 24.6309 46.9503 25.407C46.6288 26.1831 46.1576 26.8883 45.5636 27.4823L39.6698 33.3761C39.0301 34.0159 38.2621 34.5127 37.4163 34.8338C36.5705 35.1548 35.6663 35.2928 34.7632 35.2386C33.8602 35.1843 32.9789 34.9392 32.1776 34.5193C31.3763 34.0993 30.6732 33.5142 30.1148 32.8025C29.9148 32.5482 29.622 32.3838 29.3008 32.3454C28.9796 32.307 28.6563 32.3978 28.402 32.5978C28.1477 32.7977 27.9833 33.0905 27.9449 33.4117C27.9065 33.7329 27.9973 34.0562 28.1973 34.3105C28.9688 35.293 29.9399 36.1008 31.0465 36.6804C32.1532 37.26 33.3701 37.5982 34.6171 37.6729C35.8641 37.7475 37.1128 37.5568 38.2807 37.1133C39.4485 36.6699 40.5091 35.9838 41.3923 35.1003L47.2878 29.2048C48.1081 28.3844 48.7588 27.4106 49.2027 26.3388C49.6466 25.2671 49.8751 24.1183 49.8751 22.9583C49.8751 21.7982 49.6466 20.6494 49.2027 19.5777C48.7588 18.5059 48.1081 17.5321 47.2878 16.7118C46.4675 15.8914 45.4936 15.2408 44.4218 14.7968C43.3501 14.3529 42.2013 14.1244 41.0413 14.1244C39.8812 14.1244 38.7325 14.3529 37.6607 14.7968C36.5889 15.2408 35.6151 15.8914 34.7948 16.7118L29.9198 21.5868C29.6976 21.8165 29.5746 22.1243 29.5773 22.4439C29.5799 22.7634 29.7079 23.0692 29.9338 23.2952C30.1596 23.5213 30.4653 23.6496 30.7848 23.6525C31.1044 23.6555 31.4123 23.5328 31.6423 23.3109L36.5173 18.4359Z"
        fill="white"
      />
      <path
        d="M24.3304 30.6238C24.97 29.984 25.7381 29.4872 26.5838 29.1661C27.4296 28.8451 28.3339 28.7071 29.2369 28.7613C30.14 28.8155 31.0212 29.0607 31.8225 29.4806C32.6238 29.9005 33.3269 30.4857 33.8854 31.1974C33.9844 31.3233 34.1073 31.4285 34.2469 31.5069C34.3866 31.5853 34.5403 31.6355 34.6994 31.6545C34.8584 31.6735 35.0196 31.661 35.1738 31.6177C35.3281 31.5744 35.4722 31.5011 35.5981 31.4021C35.724 31.3031 35.8292 31.1803 35.9076 31.0406C35.9861 30.9009 36.0362 30.7472 36.0552 30.5882C36.0742 30.4291 36.0617 30.2679 36.0184 30.1137C35.9752 29.9595 35.9019 29.8153 35.8029 29.6894C35.0314 28.7068 34.0603 27.8991 32.9536 27.3195C31.847 26.7399 30.63 26.4016 29.383 26.327C28.136 26.2524 26.8873 26.4431 25.7195 26.8865C24.5516 27.33 23.4911 28.0161 22.6079 28.8996L16.7124 34.7951C15.8921 35.6154 15.2414 36.5893 14.7974 37.661C14.3535 38.7328 14.125 39.8815 14.125 41.0416C14.125 42.2017 14.3535 43.3504 14.7974 44.4222C15.2414 45.494 15.8921 46.4678 16.7124 47.2881C17.5327 48.1084 18.5065 48.7591 19.5783 49.2031C20.6501 49.647 21.7988 49.8755 22.9589 49.8755C24.119 49.8755 25.2677 49.647 26.3395 49.2031C27.4112 48.7591 28.3851 48.1084 29.2054 47.2881L34.0804 42.4131C34.2001 42.3016 34.2962 42.167 34.3628 42.0175C34.4294 41.868 34.4652 41.7066 34.4681 41.543C34.471 41.3793 34.4409 41.2168 34.3796 41.065C34.3183 40.9133 34.2271 40.7754 34.1113 40.6597C33.9956 40.544 33.8577 40.4527 33.706 40.3914C33.5542 40.3301 33.3917 40.3 33.228 40.3029C33.0644 40.3058 32.903 40.3416 32.7535 40.4082C32.604 40.4748 32.4695 40.5709 32.3579 40.6906L27.4829 45.5656C26.8888 46.1596 26.1835 46.6308 25.4073 46.9522C24.6311 47.2736 23.7992 47.439 22.9591 47.439C21.2624 47.4388 19.6353 46.7647 18.4357 45.5648C17.8417 44.9707 17.3705 44.2654 17.0491 43.4893C16.7277 42.7131 16.5623 41.8812 16.5624 41.0411C16.5625 39.3444 17.2367 37.7173 18.4365 36.5176L24.3304 30.6238Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_373_1899"
          x1="32"
          y1="0"
          x2="32"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B11E5" />
          <stop offset="1" stopColor="#7C8FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
